@import "variables/colors";

* {
  -webkit-font-smoothing: antialiased;
}


html {
  font-family: $font-default;
}

body {
}

p {
}

// h1
%alpha {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}


%beta {
}


%gamma {
}

%delta {

}

%epsilon {

}

%zeta {

}

// SLABTEXT
%kilo {
}