body {
  background: $color-support;
  color: $color-white;

}

.page-wrp {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.l-page {
  max-width: 900px;
  padding: 1em;
  @include breakpoint($breakpoint-narrow) {
    padding: 3em;
  }
  @include clearfix;

  position: relative;


  &:before, &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vw;
    background-color: $color-brand;
    position: absolute;
    z-index: -1;
    pointer-events: none;

    @at-root .no-flexbox & {
      display: none;
    }
  }

  &:before {
    // top: -120vw;
    width: 0;
    height: 0;
    // border-top-color: transparent;
    background: none;
    border: 50vw solid $color-brand;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform: scaleX(1.25);
    // transform-origin: bottom right;
    // transform: rotate(45deg);
    // width: 200px;
    right: 7%;
    bottom: calc(100% - 40vw);
  }

  &:after {
    // bottom: -105vw;
    // right: 0vw;
    border: 50vw solid #D93E37;
    // border: 50vw solid pink;
    z-index: 999;
    background: none;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    // border-bottom-color: transparent;
    transform: scaleX(1.25);
    // bottom: auto;
    top: calc(100% - 50vw);
    left: calc(50% - 50vw + 3em);
    // position: relative;
  }
}

.l-page__left {
  @include breakpoint($breakpoint-narrow) {
    @include grid-span(6);
    text-align: right;
  }

  .baseline {
    font-weight: 300;
    font-size: 30px;
    letter-spacing: 1.46px;
    line-height: 35px;
  }
}

.site-logo {
  @include breakpoint($breakpoint-narrow) {
    margin-top: 100px;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.l-page__right {
  @include breakpoint($breakpoint-narrow) {
    @include grid-span(6, 7);
    padding-left: 7em;
  }
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 26px;
}

ul {
  padding: 0;
  font-weight: 400px;
  font-size: 18px;
  letter-spacing: 0.61px;
  line-height: 30px;

  margin-top: 3em;
  padding-top: 3em;
  border-top: 2px solid $color-white;;
}

li {
  display: block;
  &:before {
    content: '- ';
  }
}

a {
  color: $color-white;
}